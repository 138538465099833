import React from 'react'

const Newsletter = () => {
  return (
    <div class="subscribe-area ptb-100">
    <div class="container">
    <div class="newsletter-area">
    <h2><b>Sizinlə</b> Əlaqə Saxlayaq </h2>
    <p>7/24 müraciət edin</p>
    <form class="newsletter-form" data-toggle="validator" method="POST" novalidate="true">
    <input type="email" class="form-control" placeholder="Emailinizi daxil edin" name="EMAIL" required="" autocomplete="off"></input>
    <button class="subscribe-btn disabled" type="submit" style={{pointerEvents:'all', cursor:'pointer'}}>
    Göndər
    </button>
    <div id="validator-newsletter" class="form-result"></div>
    </form>
    </div>
    </div>
    <div class="subscribe-shape">
    <img src="assets/img/subscribe-img/subscribe-shape.png" alt="Images"></img>
    </div>
    </div>
  )
}

export default Newsletter
import React from 'react'

const NotFound = () => {
  return (
   <>
   <div class="inner-banner inner-bg8">
<div class="container">
<div class="inner-title">
<h3>404 Error page!</h3>
<ul>
<li>
<a href="/">Əsas</a>
</li>
<li>404 page</li>
</ul>
</div>
</div>
<div class="inner-banner-shape">
<div class="shape1">
<img src="assets/img/inner-banner/inner-banner-shape1.png" alt="Images"></img>
</div>
<div class="shape2">
<img src="assets/img/inner-banner/inner-banner-shape2.png" alt="Images"></img>
</div>
</div>
</div>
<div class="error-area">
<div class="d-table">
<div class="d-table-cell">
<div class="error-content">
<h1>4 <span>0</span> 4</h1>
<h3>Oops! Page Not Found</h3>
<p>The page you are looking for might have been removed had its name changed or is temporarily unavailable.</p>
<a href="/" class="default-btn default-hot-toddy">
Ana səhifəyə qayıt
</a>
</div>
</div>
</div>
</div></>
  )
}

export default NotFound
import React from 'react'
import Newsletter from '../../Components/home/Newsletter/Newsletter'

const Faq = () => {
  
  return (
    <>
    <div class="inner-banner inner-bg4">
<div class="container">
<div class="inner-title">
<h3>FAQ</h3>
<ul>
<li>
<a href="/">Əsas</a>
</li>
<li>FAQ</li>
</ul>
</div>
</div>
<div class="inner-banner-shape">
<div class="shape1">
<img src="assets/img/inner-banner/inner-banner-shape1.png" alt="Images"></img>
</div>
<div class="shape2">
<img src="assets/img/inner-banner/inner-banner-shape2.png" alt="Images"></img>
</div>
</div>
</div>
<div class="faq-area faq-bg pt-100 pb-70">
<div class="container">
<div class="row">
<div class="col-lg-6">
<div class="faq-accordion">
<div class="section-title">
<span>FAQ</span>
<h2>Tez-tez verilən suallar</h2>
</div>
<ul class="accordion">
<li class="accordion-item">
<a class="accordion-title" href="javascript:void(0)">
<i class="bx bx-chevron-down"></i>
Do we have any International Accreditation ?
</a>
<div class="accordion-content">
<p>
Yes we have that option. You need to come here with a prior appointment. If you are out of India .Please send a mail to the under mentioned e mail ID or fax your reports at the mail id prescribed here.
</p>
</div>
</li>
<li class="accordion-item">
<a class="accordion-title" href="javascript:void(0)">
<i class="bx bx-chevron-down"></i>
Can we take a second opinion?
</a>
<div class="accordion-content">
<p>
Yes we have that option. You need to come here with a prior appointment. If you are out of India .Please send a mail to the under mentioned e mail ID or fax your reports at the mail id prescribed here.
</p>
</div>
</li>
<li class="accordion-item">
<a class="accordion-title" href="javascript:void(0)">
<i class="bx bx-chevron-down"></i>
Is the treatment at Medizo is affordable?
</a>
<div class="accordion-content">
<p>
Yes we have that option. You need to come here with a prior appointment. If you are out of India .Please send a mail to the under mentioned e mail ID or fax your reports at the mail id prescribed here.
</p>
</div>
</li>
<li class="accordion-item">
<a class="accordion-title" href="javascript:void(0)">
<i class="bx bx-chevron-down"></i>
What should need to get the Insurance policy?
</a>
<div class="accordion-content">
<p>
Yes we have that option. You need to come here with a prior appointment. If you are out of India .Please send a mail to the under mentioned e mail ID or fax your reports at the mail id prescribed here.
</p>
</div>
</li>
<li class="accordion-item">
<a class="accordion-title" href="javascript:void(0)">
<i class="bx bx-chevron-down"></i>
How to pay the hospital bill online?
</a>
<div class="accordion-content">
<p>
Yes we have that option. You need to come here with a prior appointment. If you are out of India .Please send a mail to the under mentioned e mail ID or fax your reports at the mail id prescribed here.
</p>
</div>
</li>
</ul>
</div>
</div>
<div class="col-lg-6">
<div class="faq-counter-area">
<div class="row">
<div class="col-lg-6 col-sm-6">
<div class="single-counter">
<h3>200+</h3>
<span>Müştəri</span>
</div>
</div>
<div class="col-lg-6 col-sm-6">
<div class="single-counter">
<h3>10+</h3>
<span>Xidmət</span>
</div>
</div>
<div class="col-lg-6 col-sm-6">
<div class="single-counter">
<h3>50+</h3>
<span>Köməkçi</span>
</div>
</div>
<div class="col-lg-6 col-sm-6">
<div class="single-counter">
<h3>25+</h3>
<span>İllik Təcrübə</span>
</div>
</div>
</div>
</div>
</div>
</div>
</div>
</div>
<Newsletter/>
</>
  )
}

export default Faq
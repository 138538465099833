import React from 'react';
import Header from '../../Components/home/Header/Header';
import Hero from '../../Components/home/Hero/Hero';
import About from '../../Components/home/About/About';
import Appointment from '../../Components/home/Appointment/Appointment';
import Services from '../../Components/home/Services/Services';
import Consulatation from '../../Components/home/Consultation/Consulatation';
import Experts from '../../Components/home/Experts/Experts';
import Emergency from '../../Components/home/Emergency/Emergency';
import News from '../../Components/home/News/News';
import Testimonial from '../../Components/home/Testimonial.js/Testimonial';
import Partners from '../../Components/home/Partners/Partners';
import Newsletter from '../../Components/home/Newsletter/Newsletter';




const Home = () => {
  return (
    <>
    <Hero/>
    <About/>
    <Appointment/>
    <Services/>
    <Consulatation/> 
    {/* <Experts/>
    <Emergency/>
    <News/>
    <Testimonial/>
    <Partners/> */}
    <Newsletter/>
    
    </>
  )
}

export default Home;

import React from 'react'

const About = () => {
  return (
    <div class="about-area pt-100 pb-70">
    <div class="container">
    <div class="row align-items-center">
    <div class="col-lg-6">
    <div class="about-img">
    <img src="assets/img/about-img/about-img.jpg" alt="Images"></img>
    </div>
    </div>
    <div class="col-lg-6">
    <div class="about-content">
    <div class="section-title">
    <span>Haqqımızda</span>
    <h2>Uzman Dr.Elmar Məmmədov</h2>
    <p>Uzm. Dr. Elmar Məmmədov – Döş qəfəsi cərrahı</p>
    <p>
    2011-ci ilin sonu etibarı ilə Baku Medical Plaza xəstəxanasında Torakal cərrah vəzifəsində çalışmaqdadır.
    </p>
    </div>
    <div class="about-card">
    <i class="flaticon-24-hours bg-one"></i>
    <div class="content">
    <span>Təhsili</span>
    <ul>
      <li>1998-2004-cı illərdə Elmar Məmmədov Azərbaycan Dövlət Tibb Universitetində təhsil alıb.</li>
      <li>2005-2011-cı illərdə Türkiyə, Ege Universiteti Döş Qəfəsi Cərrahiyyəsi bölümündə uzmanlıq keçmişdir.</li>
    </ul>
    </div>
    </div>
    <div class="about-card">
    <i class="flaticon-24-hours bg-two"></i>
    <div class="content">
    <span>Üzvlüklər</span>
    <ul>
      <li>2008-ci ildən etibarən ELMAR MƏMMƏDOV TÜSAD (Türk solunum araştırma derneğini) həqiqi üzvü.
      </li>
    </ul>
    </div>
    </div>
    <div class="about-btn">
    <a href="#" class="default-btn">Daha çox</a>
    </div>
    </div>
    </div>
    </div>
    </div>
    </div>
  )
}

export default About
import React from "react";
import ReactDOM from "react-dom/client";
// import 'react-toastify/dist/ReactToastify.css';
import './assets/css/bootstrap.min.css';
import './assets/css/responsive.scss'
import './assets/css/style.scss'
import './assets/css/animate.min.css'
// import './assets/css/boxicons.min.css'
import './assets/css/magnific-popup.min.css'
import './assets/css/nice-select.min.css'
// import './assets/css/owl.carousel.min.css'
import './assets/css/owl.theme.default.min.css'
import './assets/css/responsive.css'
import App from "./App";
import 'boxicons/css/boxicons.min.css';
// import './assets/fonts/flaticon.css'

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
      <App />
);

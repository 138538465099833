import React from 'react'

const Contact = () => {
  return (
    <>
    <div class="inner-banner inner-bg2">
<div class="container">
<div class="inner-title">
<h3>Bizimlə Əlaqə</h3>
<ul>
<li>
<a href="/">Əsas</a>
</li>
<li>Bizimlə Əlaqə</li>
</ul>
</div>
</div>
<div class="inner-banner-shape">
<div class="shape1">
<img src="assets/img/inner-banner/inner-banner-shape1.png" alt="Images"></img>
</div>
<div class="shape2">
<img src="assets/img/inner-banner/inner-banner-shape2.png" alt="Images"></img>
</div>
</div>
</div>
<div class="contact-area pt-100 pb-70">
<div class="container">
<div class="row">
<div class="col-lg-4">
<div class="contact-widget-right">
<h3>Əlaqə məlumatları</h3>
<p>Uzm. Dr. Elmar Məmmədov – Döş qəfəsi cərrahı</p>
<ul class="contact-list">
<li>
<i class='bx bx-location-plus'></i>
<div class="content">
Bakı şəhəri, Tbilisi Prospekti
</div>
</li>
<li>
<i class="flaticon-phone-call"></i>
<div class="content">
<i class='bx bx-phone' ></i>
<div class="content">
<a href="+994502668800">+994 50 266 88 00</a>
</div>
</div>
</li>
<li>
<i class="bx bxs-envelope"></i>
<div class="content">
<a href="mailto:dr.elmarmammadov@gmail.com">dr.elmarmammadov@gmail.com</a>
</div>
</li>
</ul>
</div>
</div>
<div class="col-lg-8">
<div class="contact-form">
<h2>Bizimlə əlaqə saxlayın</h2>
<form id="contactForm" novalidate="true">
<div class="row">
<div class="col-lg-6 col-sm-6">
<div class="form-group">
<input type="text" name="name" id="name" class="form-control" required="" data-error="Please enter your name" placeholder="Ad"></input>
<div class="help-block with-errors"></div>
</div>
</div>
<div class="col-lg-6 col-sm-6">
<div class="form-group">
<input type="email" name="email" id="email" class="form-control" required="" data-error="Please enter your email" placeholder="Email"></input>
<div class="help-block with-errors"></div>
</div>
</div>
<div class="col-lg-6 col-sm-6">
<div class="form-group">
<input type="text" name="phone_number" id="phone_number" required="" data-error="Please enter your number" class="form-control" placeholder="Telefon"></input>
<div class="help-block with-errors"></div>
</div>
</div>
<div class="col-lg-6 col-sm-6">
<div class="form-group">
<input type="text" name="msg_subject" id="msg_subject" class="form-control" required="" data-error="Please enter your subject" placeholder="Probleminiz"></input>
<div class="help-block with-errors"></div>
</div>
</div>
<div class="col-lg-12 col-md-12">
<div class="form-group">
<textarea name="message" class="form-control" id="message" cols="30" rows="8" required="" data-error="Write your message" placeholder="Qeyd"></textarea>
<div class="help-block with-errors"></div>
</div>
</div>
<div class="col-lg-12 col-md-12">
<button type="submit" class="default-btn disabled" style={{pointerEvents:"all", cursor:"pointer"}}>
Göndər
</button>
<div id="msgSubmit" class="h3 text-center hidden"></div>
<div class="clearfix"></div>
</div>
</div>
</form>
</div>
</div>
</div>
</div>
</div>
<div class="map-area">
<div class="container-fluid m-0 p-0">
<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3038.508929237635!2d49.8138155!3d40.3975751!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40308778b9b607a1%3A0xc595257d375223b2!2sTbilisi%20prospekti%2C%20Bak%C4%B1!5e0!3m2!1saz!2saz!4v1723446666508!5m2!1saz!2saz" width="600" height="450" frameborder="0" style={{border:"0"}} allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>
</div>
</div>
</>
  )
}

export default Contact
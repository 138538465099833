import React from 'react'
import AboutUs from '../../Components/home/About/About';
import Emergency from '../../Components/home/Emergency/Emergency';
import Serve from '../../Components/Serve/Serve';

const About = () => {
  return (
   <>
    <div class="inner-banner inner-bg1">
<div class="container">
<div class="inner-title">
<h3>Haqqımızda</h3>
<ul>
<li>
<a href="/">Əsas</a>
</li>
<li>Haqqımızda</li>
</ul>
</div>
</div>
<div class="inner-banner-shape">
<div class="shape1">
<img src="assets/img/inner-banner/inner-banner-shape1.png" alt="Images"></img>
</div>
<div class="shape2">
<img src="assets/img/inner-banner/inner-banner-shape2.png" alt="Images"></img>
</div>
</div>
</div>
<div class="about-area pt-100 pb-70">
    <div class="container">
    <div class="row align-items-center">
    <div class="col-lg-6">
    <div class="about-img">
    <img src="assets/img/about-img/about-img.jpg" alt="Images"></img>
    </div>
    </div>
    <div class="col-lg-6">
    <div class="about-content">
    <div class="section-title">
    <span>Haqqımızda</span>
    <h2>Uzman Dr.Elmar Məmmədov</h2>
    <p>Uzm. Dr. Elmar Məmmədov – Döş qəfəsi cərrahı</p>
    <p>
    2011-ci ilin sonu etibarı ilə Baku Medical Plaza xəstəxanasında Torakal cərrah vəzifəsində çalışmaqdadır.
    </p>
    </div>
    <div class="about-card">
    <i class="flaticon-24-hours bg-one"></i>
    <div class="content">
    <span>Təhsili</span>
    <ul>
      <li>1998-2004-cı illərdə Elmar Məmmədov Azərbaycan Dövlət Tibb Universitetində təhsil alıb.</li>
      <li>2005-2011-cı illərdə Türkiyə, Ege Universiteti Döş Qəfəsi Cərrahiyyəsi bölümündə uzmanlıq keçmişdir.</li>
    </ul>
    </div>
    </div>
    <div class="about-card">
    <i class="flaticon-ambulance-2 bg-two"></i>
    <div class="content">
    <span>İş təcrübəsi</span>
    <ul>
      <li>2011-2013-cü illərdə DOKTOR ELMAR MƏMMƏDOV Mərkəzi Neftçilər Xəstəxanasında torokal cərrah kimi çalışıb.</li>
      <li>2014-2017-ci illərdə Bakı sağlamlıq mərkəzində Torokal cərrah kimi çalışıb.</li>
      <li>2017-ci ildən 2018-ci ilədək ELMAR MƏMMƏDOV Zəfəran Hospitalda və Respublika Diaqnostika Mərkəzində torokal cərrah vəzifəsində çalışıb</li>
    <li>2018-2020-ci illərdə torakal cərrah Elmar Məmmədov Bona Dea İnternational Clinik-də çalışıb.</li>
    <li>2020-ci ildən indiyədək doktor Elmar Məmmədov Baku Medical Plaza Xəstəxanasında çalışır</li>
    <li>DOKTOR ELMAR MƏMMƏDOVUN HAL-HAZIRKİ İŞ YERİ:
    BAKU MEDİCAL PLAZA</li>
    </ul>
    </div>
    </div>
    <div class="about-card">
    <i class="flaticon-24-hours bg-one"></i>
    <div class="content">
    <span>Üzvlüklər</span>
    <ul>
      <li>2008-ci ildən etibarən ELMAR MƏMMƏDOV TÜSAD (Türk solunum araştırma derneğini) həqiqi üzvü.
      </li>
    </ul>
    </div>
    </div>
    </div>
    </div>
    </div>
    </div>
    </div>
<Serve/>
<Emergency/>
</>
  )
}

export default About
import React from 'react'

const Hero = () => {
  return (
    <>
    <div class="banner-area banner-bg">
<div class="container">
<div class="row">
<div class="col-lg-7">
<div class="banner-content">
<span>Uzman Dr.Elmar Məmmədov</span>
<h1>Tənəffüs həyatdır</h1>
<p>Rezervasiya təyin edin</p>
<div class="banner-btn">
<a href="appointment.html" class="appointment-btn">Görüş təyin edin</a>
</div>
</div>
</div>
</div>
</div>
<div class="banner-img">
<img src="assets/img/home-one/home-one-img.png" alt="Images"></img>
</div>
<div class="banner-shape">
<div class="shape1">
<img src="assets/img/home-one/shape1.png" alt="Images"></img>
</div>
<div class="shape2">
<img src="assets/img/home-one/shape2.png" alt="Images"></img>
</div>
</div>
</div>
<div class="banner-bottom">
<div class="container">
<div class="row">
<div class="col-lg-6">
<div class="banner-bottom-card">
<i class="flaticon-call"></i>
<div class="content">
<span>24/7 Xidmət</span>
<h3><a href="tel:812345678912">+994 50 266 88 00</a></h3>
</div>
</div>
</div>
</div>
</div>
</div>
</>
  )
}

export default Hero
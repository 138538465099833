import React, { useState } from 'react';
import { FaSearch, FaUser, FaQuestionCircle, FaShoppingCart, FaTh, FaChevronRight } from 'react-icons/fa';
import './MeanMenu.css'

const Header = () => {

  return (
    <>
       <div class="navbar-area">
       <div class="mobile-nav mean-container">
        <div class="mean-bar"><a href="#nav" class="meanmenu-reveal" style={{right:'0', left:'auto'}}><span><span><span></span></span></span></a><nav class="mean-nav">
<ul class="navbar-nav m-auto" style={{display:'none'}}>
<li class="nav-item">
<a href="#" class="nav-link active">
Home
<i class="bx bx-chevron-down"></i>
</a>
<a class="mean-expand" href="#" style={{fontSize:'18px'}}>+</a></li>
<li class="nav-item">
<a href="about.html" class="nav-link">
About
</a>
</li>
<li class="nav-item">
<a href="#" class="nav-link">
Pages
<i class="bx bx-chevron-down"></i>
</a>
<ul class="dropdown-menu" style={{display:'none'}}>
<li class="nav-item">
<a href="appointment.html" class="nav-link">
Appointment
</a>
</li>
<li class="nav-item">
<a href="#" class="nav-link">
Case study
<i class="bx bx-chevron-down"></i>
</a>
<ul class="dropdown-menu" style={{display:'none'}}>
<li class="nav-item">
<a href="case-study.html" class="nav-link">
Case study
</a>
</li>
<li class="nav-item">
<a href="case-details.html" class="nav-link">
Case study Details
</a>
</li>
</ul>
<a class="mean-expand" href="#" style={{fontSize:'18px'}}>+</a></li>
<li class="nav-item">
<a href="departments.html" class="nav-link">
Departments
</a>
</li>
<li class="nav-item">
<a href="faq.html" class="nav-link">
FAQ
</a>
</li>
<li class="nav-item">
<a href="testimonials.html" class="nav-link">
Testimonials
</a>
</li>
<li class="nav-item">
<a href="sign-in.html" class="nav-link">
Sign In
</a>
</li>
<li class="nav-item">
<a href="sign-up.html" class="nav-link">
Sign Up
</a>
</li>
<li class="nav-item">
<a href="terms-condition.html" class="nav-link">
Terms &amp; Conditions
</a>
</li>
<li class="nav-item">
<a href="privacy-policy.html" class="nav-link">
Privacy Policy
</a>
</li>
<li class="nav-item">
<a href="404.html" class="nav-link">
404 page
</a>
</li>
<li class="nav-item">
<a href="coming-soon.html" class="nav-link">
Coming Soon
</a>
</li>
</ul>
<a class="mean-expand" href="#" style={{fontSize:'18px'}}>+</a></li>
<li class="nav-item">
<a href="#" class="nav-link">
Doctors
<i class="bx bx-chevron-down"></i>
</a>
<ul class="dropdown-menu" style={{display:'none'}}>
<li class="nav-item">
<a href="doctors.html" class="nav-link">
Doctors
</a>
</li>
<li class="nav-item">
<a href="doctors-details.html" class="nav-link">
Doctors Details
</a>
</li>
</ul>
<a class="mean-expand" href="#" style={{fontSize:'18px'}}>+</a></li>
<li class="nav-item">
<a href="#" class="nav-link">
Services
<i class="bx bx-chevron-down"></i>
</a>
<ul class="dropdown-menu" style={{display:'none'}}>
<li class="nav-item">
<a href="services-1.html" class="nav-link">
Services Style One
</a>
</li>
<li class="nav-item">
<a href="services-2.html" class="nav-link">
Services Style Two
</a>
</li>
<li class="nav-item">
<a href="service-details.html" class="nav-link">
Service Details
</a>
</li>
</ul>
<a class="mean-expand" href="#" style={{fontSize:'18px'}}>+</a></li>
<li class="nav-item">
<a href="#" class="nav-link">
Blog
<i class="bx bx-chevron-down"></i>
</a>
<ul class="dropdown-menu" style={{display:'none'}}>
<li class="nav-item">
<a href="blog-1.html" class="nav-link">
Blog Style One
</a>
</li>
<li class="nav-item">
<a href="blog-2.html" class="nav-link">
Blog Style Two
</a>
</li>
<li class="nav-item">
<a href="blog-details.html" class="nav-link">
Blog Details
</a>
</li>
</ul>
<a class="mean-expand" href="#" style={{fontSize:'18px'}}>+</a></li>
<li class="nav-item">
<a href="#" class="nav-link">
Shop
<i class="bx bx-chevron-down"></i>
</a>
<ul class="dropdown-menu" style={{display:'none'}}>
<li class="nav-item">
<a href="shop.html" class="nav-link">
Shop
</a>
</li>
<li class="nav-item">
<a href="cart.html" class="nav-link">
Cart
</a>
</li>
<li class="nav-item">
<a href="checkout.html" class="nav-link">
Checkout
</a>
</li>
<li class="nav-item">
<a href="shop-details.html" class="nav-link">
Shop Details
</a>
</li>
<li class="nav-item">
<a href="wishlist.html" class="nav-link">
Wishlist
</a>
</li>
</ul>
<a class="mean-expand" href="#" style={{fontSize:'18px'}}>+</a></li>
<li class="nav-item mean-last">
<a href="contact.html" class="nav-link">
Contact
</a>
</li>
</ul>
<div class="others-options d-flex align-items-center">
<div class="option-item">
<i class="search-btn bx bx-search"></i>
<i class="close-btn bx bx-x"></i>
<div class="search-overlay search-popup">
<div class="search-box">
<form class="search-form">
<input class="search-input" name="search" placeholder="Search" type="text"></input>
<button class="search-button" type="submit">
<i class="bx bx-search"></i>
</button>
</form>
</div>
</div>
</div>
<div class="option-item">
<div class="add-cart-btn">
<a href="#" class="cart-btn-icon">
<i class="bx bx-shopping-bag"></i>
<span>0</span>
</a>
</div>
</div>
</div>
</nav></div>
<a href="/" class="logo">
<img src="assets/img/logo.png" class="logo-one" alt="Logo"></img>
<img src="assets/img/logo-2.png" class="logo-two" alt="Logo"></img>
</a>
</div>


<div class="main-nav">
<div class="container">
<nav class="navbar navbar-expand-md navbar-light ">
<a class="navbar-brand" href="/">
<img src="assets/img/logo.png" class="logo-one" alt="Logo"></img>
<img src="assets/img/logo-2.png" class="logo-two" alt="Logo"></img>
</a>
<div class="collapse navbar-collapse mean-menu" id="navbarSupportedContent" style={{display:'block'}}>
<ul class="navbar-nav m-auto">
<li class="nav-item">
<a href="/" class="nav-link">
Əsas
</a>
</li>
<li class="nav-item">
<a href="/about" class="nav-link">
Haqqımızda
</a>
</li>
<li class="nav-item">
<a href="/services" class="nav-link">
Xidmətlər
</a>
</li>
<li class="nav-item">
<a href="/appointment" class="nav-link">
Rezervasiya
</a>
</li>
<li class="nav-item">
<a href="/faq" class="nav-link">
FAQ
</a>
</li>
<li class="nav-item">
<a href="/contact" class="nav-link">
Əlaqə
</a>
</li>
</ul>
<div class="others-options d-flex align-items-center">
<button type="submit" class="default-btn">
    Görüş təyin et
    </button>
</div>
</div>
</nav>
</div>
</div>
<div class="side-nav-responsive">
<div class="container">
<div class="dot-menu">
<div class="circle-inner">
<div class="circle circle-one"></div>
<div class="circle circle-two"></div>
<div class="circle circle-three"></div>
</div>
</div>
<div class="container">
<div class="side-nav-inner">
<div class="side-nav justify-content-center align-items-center">
<div class="side-item">
<div class="option-item">
<i class="search-btn bx bx-search"></i>
<i class="close-btn bx bx-x"></i>
<div class="search-overlay search-popup">
<div class="search-box">
<form class="search-form">
<input class="search-input" name="search" placeholder="Search" type="text"></input>
<button class="search-button" type="submit">
<i class="bx bx-search"></i>
</button>
</form>
</div>
</div>
</div>
<div class="option-item">
<div class="add-cart-btn">
<a href="#" class="cart-btn-icon">
<i class="bx bx-shopping-bag"></i>
<span>0</span>
</a>
</div>
</div>
</div>
</div>
</div>
</div>
</div>
</div>
</div>
    </>
  );
};

export default Header;





























import React from 'react'
import Services from '../../Components/home/Services/Services'
import Appointment from '../../Components/home/Appointment/Appointment'

const ServicesUs = () => {
  return (
   <>
   <div class="inner-banner inner-bg7">
<div class="container">
<div class="inner-title">
<h3> Xidmətlər </h3>
<ul>
<li>
<a href="/">Əsas</a>
</li>
<li> Xidmətlər </li>
</ul>
</div>
</div>
<div class="inner-banner-shape">
<div class="shape1">
<img src="assets/img/inner-banner/inner-banner-shape1.png" alt="Images"></img>
</div>
<div class="shape2">
<img src="assets/img/inner-banner/inner-banner-shape2.png" alt="Images"></img>
</div>
</div>
</div>
<Services/>
<Appointment/>
<div class="banner-bottom-three pt-100 pb-70">
<div class="container">
<div class="row">
<div class="col-lg-4 col-sm-6">
<div class="banner-bottom-item">
<i class="flaticon-first-aid-kit"></i>
<h3>Konsultasiya</h3>
<p>Lorem ipsum dolor sit amet, con setetur sadipscing elitr, sed.</p>
<div class="circle"></div>
<div class="line"></div>
</div>
</div>
<div class="col-lg-4 col-sm-6">
<div class="banner-bottom-item">
<i class="flaticon-fast-delivery"></i>
<h3>Təcili müdaxilə</h3>
<p>Lorem ipsum dolor sit amet, con setetur sadipscing elitr, sed.</p>
<div class="circle"></div>
<div class="line"></div>
</div>
</div>
<div class="col-lg-4 col-sm-6 offset-lg-0 offset-sm-3">
<div class="banner-bottom-item">
<i class="flaticon-laptop"></i>
<h3>Peşəkar mütəxəssis</h3>
<p>Lorem ipsum dolor sit amet, con setetur sadipscing elitr, sed.</p>
<div class="circle"></div>
<div class="line"></div>
</div>
</div>
</div>
</div>
</div>
</>
  )
}

export default ServicesUs
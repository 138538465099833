import React from 'react'

const services = [
    {
      title: 'Medical Counselling',
      image: 'path-to-image-1.png',
      icon: 'fas fa-user-md',
    },
    {
      title: 'Laboratory Test',
      image: 'path-to-image-2.png',
      icon: 'fas fa-flask',
    },
    {
      title: 'Surgical Operation',
      image: 'path-to-image-3.png',
      icon: 'fas fa-syringe',
    },
    {
      title: 'Medical Treatment',
      image: 'path-to-image-4.png',
      icon: 'fas fa-stethoscope',
    },
    {
      title: 'Pharmacy Service',
      image: 'path-to-image-5.png',
      icon: 'fas fa-pills',
    },
    {
      title: 'Emergency Service',
      image: 'path-to-image-6.png',
      icon: 'fas fa-ambulance',
    },
  ];

const Services = () => {
  return (
    <section class="service-area pt-100 pb-70">
    <div class="container">
    <div class="section-title text-center">
    <h2>Təmin etdiyimiz xidmətlər</h2>
    <div class="section-icon">
    <div class="icon">
    <i class="flaticon-dna"></i>
    </div>
    </div>
    <p>
   Torokal və döş qəfəsi ilə bağlı bütün problemlerinizi həll edin
    </p>
    </div>
    <div class="row pt-45">
    <div class="col-lg-4 col-md-6">
    <div class="service-card">
    <a href="service-details.html"><img src="assets/img/services/service-img2.jpg" alt="Images"></img></a>
    <div class="service-content">
    <div class="service-icon">
    <i class="flaticon-syringe"></i>
    </div>
    <h3><a href="service-details.html">Ağciyər Xərçəngi Cərrahiyyəsi</a></h3>
    <div class="content">
    <p>Ağciyər xərçənginin cərrahi müalicəsi, şişin çıxarılması üçün lobektomiya, pnevmonektomiya kimi əməliyyatları əhatə edir.</p>
    </div>
    </div>
    <div class="service-shape-1">
    <img src="assets/img/services/service-shape1.png" alt="Images"></img>
    </div>
    <div class="service-shape-2">
    <img src="assets/img/services/service-shape2.png" alt="Images"></img>
    </div>
    </div>
    </div>
    <div class="col-lg-4 col-md-6">
    <div class="service-card">
    <a href="service-details.html"><img src="assets/img/services/service-img3.jpg" alt="Images"></img></a>
    <div class="service-content">
    <div class="service-icon">
    <i class="flaticon-male"></i>
    </div>
    <h3><a href="service-details.html">Sinə Divarı Cərrahiyyəsi:</a></h3>
    <div class="content">
    <p>Sinə divarında olan kütlələr, deformasiyalar və ya travmatik zədələrin cərrahi olaraq düzəldilməsi və bərpası.</p>
    </div>
    </div>
    <div class="service-shape-1">
    <img src="assets/img/services/service-shape1.png" alt="Images"></img>
    </div>
    <div class="service-shape-2">
    <img src="assets/img/services/service-shape2.png" alt="Images"></img>
    </div>
    </div>
    </div>
    <div class="col-lg-4 col-md-6">
    <div class="service-card">
    <a href="service-details.html"><img src="assets/img/services/service-img4.jpg" alt="Images"></img></a>
    <div class="service-content">
    <div class="service-icon">
    <i class="flaticon-stethoscope-1"></i>
    </div>
    <h3><a href="service-details.html">Plevral Xəstəliklərin Cərrahiyyəsi:</a></h3>
    <div class="content">
    <p>Plevral effuziya, plevral şişlər və ya pnevmotoraks kimi plevral xəstəliklərin müalicəsi.</p>
    </div>
    </div>
    <div class="service-shape-1">
    <img src="assets/img/services/service-shape1.png" alt="Images"></img>
    </div>
    <div class="service-shape-2">
    <img src="assets/img/services/service-shape2.png" alt="Images"></img>
    </div>
    </div>
    </div>
    <div class="col-lg-4 col-md-6">
    <div class="service-card">
    <a href="service-details.html"><img src="assets/img/services/service-img5.jpg" alt="Images"></img></a>
    <div class="service-content">
    <div class="service-icon">
    <i class="flaticon-caduceus-symbol"></i>
    </div>
    <h3><a href="service-details.html">Mediastinal Şiş Cərrahiyyəsi</a></h3>
    <div class="content">
    <p>Mediastin bölgəsində yerləşən şişlərin (timoma, lenfoma kimi) cərrahi olaraq çıxarılması.</p>
    </div>
    </div>
    <div class="service-shape-1">
    <img src="assets/img/services/service-shape1.png" alt="Images"></img>
    </div>
    <div class="service-shape-2">
    <img src="assets/img/services/service-shape2.png" alt="Images"></img>
    </div>
    </div>
    </div>
    <div class="col-lg-4 col-md-6">
    <div class="service-card">
    <a href="service-details.html"><img src="assets/img/services/service-img6.jpg" alt="Images"></img></a>
    <div class="service-content">
    <div class="service-icon">
    <i class="flaticon-ambulance-2"></i>
    </div>
    <h3><a href="service-details.html">Bronxoskopiya və Torakoskopiya</a></h3>
    <div class="content">
    <p>Diaqnostika və müalicə məqsədilə bronxoskopiya (bronxların içinin görüntülənməsi) və torakoskopiya (sinə boşluğunun endoskopik araşdırılması) kimi minimal invaziv prosedurlar.</p>
    </div>
    </div>
    <div class="service-shape-1">
    <img src="assets/img/services/service-shape1.png" alt="Images"></img>
    </div>
    <div class="service-shape-2">
    <img src="assets/img/services/service-shape2.png" alt="Images"></img>
    </div>
    </div>
    </div>
    <div class="col-lg-4 col-md-6">
    <div class="service-card">
    <a href="service-details.html"><img src="assets/img/services/service-img1.jpg" alt="Images"></img></a>
    <div class="service-content">
    <div class="service-icon">
    <i class="flaticon-doctor"></i>
    </div>
    <h3><a href="service-details.html">Trakea və Bronx Cərrahiyyəsi</a></h3>
    <div class="content">
    <p>Trakea (nəfəs borusu) və bronxların daralması və ya tıxanması hallarında aparılan cərrahi müdaxilələr.</p>
    </div>
    </div>
    <div class="service-shape-1">
    <img src="assets/img/services/service-shape1.png" alt="Images"></img>
    </div>
    <div class="service-shape-2">
    <img src="assets/img/services/service-shape2.png" alt="Images"></img>
    </div>
    </div>
    </div>
    </div>
    </div>
    <div class="service-dots">
    <img src="assets/img/services/service-dots.png" alt=""></img>
    </div>
    </section>
  )
}

export default Services
import React from 'react'

const Serve = () => {
  return (
    <div class="serve-area ptb-100">
<div class="container">
<div class="section-title text-center">
<h2>Doktor Elmar Məmmədovu daha yaxından tanıyın</h2>
<div class="section-icon">
<div class="icon">
<i class="flaticon-dna"></i>
</div>
</div>
</div>
<div class="serve-btn-area">
<a href="https://youtu.be/gVFKzcv7_0E?si=nK4N4jK1ASfhk_3L" class="play-btn"><i class="bx bx-play"></i></a>
</div>
</div>
</div>
  )
}

export default Serve